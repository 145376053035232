.yesplz-price-range-container {
  padding: 40px 45px;

  .yesplz-price-range-values {
    display: flex;
    justify-content: space-between;
    padding-top: 18px;
    margin: 0 -7px;
    cursor: default;

    span {
      font-size: 14px;
    }
  }
}

.yesplz-price-range-slider {
  &.noUi-target {
    border-color: #1b1b1d;
    border-radius: 2px;
    box-shadow: none;
    background-color: #ffffff;
  }

  &.noUi-horizontal {
    height: 5px;
  }

  .noUi-base {
    .noUi-connects {
      .noUi-connect {
        background-color: #1b1b1d;

        &[style="transform: translate(0%, 0px) scale(1, 1);"] {
          background-color: #ffffff;
        }
      }
    }

    .noUi-origin {
      .noUi-handle {
        border-color: #1b1b1d;
        box-shadow: none;
        border-radius: 50%;
        width: 34px;
        height: 34px;
        top: -15px;
        outline: none;

        .noUi-touch-area {
          outline: none;
        }

        &::after, &::before {
          display: none;
        }
      }
    }
  }

  &[disabled="true"] {
    opacity: 0.3;

    + .yesplz-price-range-values {
      opacity: 0.3;
    }
  }
}