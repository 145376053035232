$c: 'MobileTabbed';

.MobileTabbedFilters-container {
  position: relative;
  z-index: 100;

  @include media-breakpoint-up(md) {
    z-index: auto;
  }

  * {
    box-sizing: border-box;
  }
}

.MobileTabbedFilters {
  background-color: #ffffff;

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  transition: transform 0.4s ease;

  &-Foldable {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    padding-bottom: 60px;

    @include media-breakpoint-up(md) {
      z-index: 0;
    }
  }

  @include media-breakpoint-up(md) {
    width: 348px;
    z-index: 0;
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    height: auto;
    overflow: visible;
    transform: translate3d(0, 0, 0) !important;
  }

  &-pointer {
    z-index: 5;
  }

  &-header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 4px 20px;
    height: 45px;
    position: relative;
    border-bottom: 1px solid #f0f1f3;
    background-color: #ffffff;

    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 6;

    @include media-breakpoint-up(md) {
      padding-left: 0;
      padding-right: 0;
      margin-left: 20px;
      margin-right: 20px;
    }

    &-actions {
      flex: 1;
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      h3 {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 41px;
      }
    }

    &-close {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: -10px;

      @include media-breakpoint-up(md) {
        display: none;
      }

      > img {
        vertical-align: top;
        width: 16px;
        height: 16px;
        opacity: 0.6;
      }
    }
  }

  .simple-presets-occasions-tags {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0;
    margin-right: 0;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &-tabs-container {
    flex: 1;
    display: flex;
    align-items: stretch;
  }

  &-tabs-titles {
    width: 156px;
    height: calc(100vh - 60px - 45px);
    position: relative;
    z-index: 1;
    flex: none;
    overflow-y: auto;
    padding-bottom: 30px;
    background-color: #ffffff;

    .MobileTabbed-title {
      padding: 16px 20px;

      h4 {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        margin: 0;
      }

      &.is-active {
        background-color: #F7F7F7;
        h4 {
          font-weight: 700;
        }
      }

      &.is-hidden {
        display: none;
      }
    }
  }
  
  &-tabs {
    flex: 1;
    background-color: #F7F7F7;
    display: flex;
    align-items: stretch;
    position: relative;
    z-index: 2;

    &.low-index {
      z-index: 0;
    }

    &-content {
      overflow-x: hidden;
      overflow-y: auto;

      // position: absolute;
      // top: 0;
      // bottom: 0;
      // left: 0;
      // right: 0;

      flex: 1;
      display: flex;
      align-items: stretch;

      > div {
        width: 100%;
      }
    }
  }

  &-footer {
    margin-top: 30px;
    padding: 0;
    position: absolute;
    bottom: 0;
    z-index: 6;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px -15px 30px 10px #ffffff;
    display: flex;

    @include media-breakpoint-up(md) {
      display: none;
    }

    button {
      border: 1px solid #2F30EB;
      background-color: #2F30EB;
      padding: 10px;
      color: #ffffff;
      font-size: 14px;
      width: 100%;
      height: 48px;
      flex: 1;

      &:first-child {
        border: 1px solid #2F30EB;
        background-color: #ffffff;
        border-right: 1px solid #ffffff;
        color: #2F30EB;
        width: 156px;
        flex: none;
      }

      svg {
        margin-left: 10px;
      }
    }
  }
}

.#{$c} {
  padding: 16px 18px;
  display: none;

  height: calc(100vh - 45px - 80px);
  overflow-y: auto;

  > div h3 {
    display: none;
  }

  &.is-active {
    display: block;
  }

  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: #f0f1f3;

    position: absolute;
    bottom: 0;
    left: 24px;
    right: 24px;
    z-index: 4;
  }

  .ThumbnailPicker {
    padding: 0;
    padding-bottom: 15px;

    margin-left: 0;
    margin-right: 0;

    width: auto;
    justify-content: space-between;
    align-items: stretch;
    position: relative;

    @include media-breakpoint-up(md) {
      margin-left: 0;
      margin-right: 0;
    }

    .ThumbnailPickerOption {
      margin-right: 0;

      .ThumbnailPickerOption-thumbnail {
        background-color: #ffffff;
        border-radius: 50%;
        width: 64px;
        height: 64px;

        &::before {
          background-color: transparent;
          max-height: 100%;
          max-width: 100%;
          border: solid 1px #dddfe2;
          opacity: 1;
        }

        &--borderless {
          &::before {
            border-color: transparent;
          }
        }

        .ThumbnailPickerOption-imageWrapper {
          img {
            display: block;
            width: 27px;
            height: 27px;
          }

          .thumbnail-item {
            width: 64px;
            height: 64px;
          }

          .silhouetteTN {
            background-color: #F0F0F0;
            transform: scale(1);
            top: 0;
            left: 1px;

            svg {
              g[fill="#01070D"] {
                fill: #F0F0F0;
              }

              *[fill="#FAFAFA"] {
                fill: #F0F0F0;
              }
            }

            &.all {
              background-color: #F0F0F0;
            }
          }
        }
      }

      &.is-active {
        .ThumbnailPickerOption-thumbnail {
          &::before {
            border: solid 2px #2F30EB;
          }
        }
      }

      &.reset {
        .ThumbnailPickerOption-thumbnail {
          .ThumbnailPickerOption-imageWrapper {
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  .ColorPallete {
    padding: 0;
    justify-content: space-between;
    padding-bottom: 25px;

    .ColorButton-wrapper {
      width: auto;

      &:nth-child(3n+1), &:nth-child(3n+3) {
        width: 48px;
      }

      &:nth-child(3n+2) {
        width: calc(100% - (48px*2));
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .ColorButton {
      border: 0;
      box-shadow: none;
      width: 48px;

      &-color-wrapper {
        width: 48px;
        height: 48px;
      }

      &-color {
        width: 100%;
        height: 100%;
      }

      .ColorButton-mask::before {
        background-size: 14px 12px;
      }

      &.clear {
        .ColorButton-color-wrapper {
          border: solid 1px #dddfe2;

          img {
            width: 21px;
            height: 21px;
          }
        }
      }

      &.active {
        background: transparent !important;

        .ColorButton-color-wrapper {
          padding: 4px;
          border: solid 1px #dddfe2;
        }
      }
    }
  }

  &[data-filter="style"] {
    > h3 {
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      margin-top: 0;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
  }

  &[data-filter="patterns"], &[data-filter="wash"] {
    .ThumbnailPicker {
      justify-content: flex-start;

      .ThumbnailPickerOption {
        &:nth-child(3n+1), &:nth-child(3n+3) {
          width: 48px;
        }
  
        &:nth-child(3n+2) {
          width: calc(100% - (48px*2));
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .ThumbnailPickerOption-thumbnail {
          width: 48px;
          height: 48px;

          .ThumbnailPickerOption-imageWrapper {
            img {
              width: 48px;
              height: 48px;
            }
          }
        }

        &.is-active {
          .ThumbnailPickerOption-thumbnail {
            &::before {
              border-width: 2.5px;
            }
          }
        }

        &.reset {
          .ThumbnailPickerOption-thumbnail {
            .ThumbnailPickerOption-imageWrapper {
              img {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
  
        &.patterns-pattern {
          .ThumbnailPickerOption-thumbnail {
            .ThumbnailPickerOption-imageWrapper {
              img {
                width: 23px;
                height: 23px;
              }
            }
          }
        }
  
        &.patterns-solid, &.pattern-solid, &.patterns-detail, &.pattern-detail {
          .ThumbnailPickerOption-thumbnail {
            .ThumbnailPickerOption-imageWrapper {
              img {
                width: 38px;
                height: 38px;
              }
            }
          }
        }
  
        &.patterns-floral, &.pattern-floral {
          .ThumbnailPickerOption-thumbnail {
            .ThumbnailPickerOption-imageWrapper {
              img {
                width: 48px;
                height: 48px;
              }
            }
          }
        }
  
        &.pattern-animal, &.pattern-washed_dark, &.pattern-washed_medium,
        &.pattern-washed_light, &.pattern-stripe, &.pattern-checker,
        &.pattern-graphic, &.pattern-colorblock, &.pattern-dot,
        &.pattern-fruit, &.pattern-repeat, &.pattern-sparkle,
        &.wide-image {
          .ThumbnailPickerOption-thumbnail {
            .ThumbnailPickerOption-imageWrapper {
              img {
                width: 48px;
                height: 48px;
              }
            }
          }
        }
      }
    }
  }

  .yesplz-body-parts {
    h3 {
      display: block;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      opacity: 0.6;
    }

    .ThumbnailPicker {
      justify-content: space-between;
      .ThumbnailPickerOption {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }

  .yesplz-price-range-container {
    padding: 20px 12px;
  }

  .yesplz-price-range-slider {
    &.noUi-horizontal {
      height: 4px;
    }

    &.noUi-target {
      border: 0;
      border-radius: 2px;
      background-color: #dddfe2;
    }

    .noUi-base {
      .noUi-connects {
        .noUi-connect {
          background-color: #131314;
        }
      }

      .noUi-origin {
        .noUi-handle {
          border: solid 2px #ffffff;
          background-color: #131314;
          width: 16px;
          height: 16px;
          top: -6px;
          right: -7px;

          .noUi-touch-area {
            width: 400%;
            height: 400%;
            transform: translate3d(-18px, -18px, 0);
          }
        }
      }
    }
  }

  .yesplz-price-from-to-filter {
    display: flex;
    justify-content: space-between;

    * {
      box-sizing: border-box;
    }

    span {
      display: block;
      width: 48%;

      input {
        width: 100%;
        border-radius: 8px;
        border: solid 1px #dddfe2;
        line-height: 16px;
        padding: 12px 16px;
        color: #8b8c8f;
        box-shadow: none;
        -webkit-appearance: none;

        &:focus {
          outline: none
        }
      }
    }
  }

  .simple-categories-list {
    padding-bottom: 25px;
  }

  .EditorsPicks {
    &-container {
      padding: 0;
      margin: 0 -5px;

      .EditorsPicks-Item {
        border-radius: 8px;
        box-shadow: 0px 0px 0px 1px #dddfe2;
        margin-left: 6px;
        margin-right: 6px;
        width: auto;
        padding: 8.5px 17px;

        h5 {
          font-size: 14px;
          text-transform: none;
        }

        &.is-active {
          box-shadow: 0px 0px 0px 1px #131314;
          background-color: #131314;

          h5 {
            color: #ffffff;
          }
        }
      }
    }
  }

  .VisualFilter {
    position: relative;
    margin-left: -18px;
    margin-right: -18px;

    &:before {
      content: "";
      width: 68.50828729281768%;
      height: 97.637795275590551%;
      background: #F0F0F0;
      opacity: 1;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      margin: auto;
      z-index: 0;
    }

    svg {
      position: relative;
      z-index: 1;

      .vf-background {
        fill: #F0F0F0;
      }
    }
  }

  .yesplz-tree-view {
    margin-top: -16px;
    margin-left: -18px;
    margin-right: -18px;

    ul {
      li {
        padding: 16px 18px;
        margin-bottom: 0 !important;

        ul {
          display: none;
        }
      }
    }
  }

  .yesplz-brand-filter {
    input {
      padding-top: 3px;
      padding-bottom: 3px;
      height: 32px;
    }

    .brands-selected {
      margin-left: -18px;
      margin-right: -18px;

      span {
        background-color: #ffffff;
      }
    }

    ul {
      list-style-type: none !important;
      overflow: visible;
      max-height: none;

      li {
        cursor: pointer;
        position: relative;
        padding: 16px 18px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        margin-left: -18px;
        margin-right: -18px;
        color: #777777;
        text-transform: uppercase;

        span {
          font-size: 13px;
        }

        &::before, &::after {
          display: none;
        }

        &.active, &.is-selected {
          background-color: #ffffff;
          color: #000000;
          font-weight: 600;

          &::before {
            display: block;
            content: "";
            width: 11px;
            height: 11px;
            background: url('~@yesplz/core-web/assets/images/mobile-tabbed-check.svg') transparent no-repeat center center;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translate3d(-50%, -50%, 0);
            margin: auto;
          }
        }

        &.is-disabled {
          opacity: 0.3;
          cursor: default;
        }

        &.header {
          padding: 12px 18px;
          cursor: default;

          span {
            font-weight: bold;
            color: #000000;
          }

          &::before, &::after {
            display: none;
          }
        }
      }
    }
  }

  &[data-filter="categories"].is-active {
    display: flex;
    align-items: stretch;

    > div {
      flex: 1;
      display: flex;
      align-items: stretch;
    }
  }

  &-tree-view {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: #F7F7F7;
    transition: all 0.3s ease-in-out;

    ul {
      margin-bottom: 0;
      transition: all 0.3s ease-in-out;

      li {
        display: flex;
        align-items: stretch;
        margin-bottom: 0 !important;

        span {
          flex: 1;
          display: block;
          padding: 14px 18px;
          text-transform: uppercase;
        }

        i {
          display: block;
          width: 32px;
          position: relative;

          &::before, &::after {
            content: "";
            display: block;
            width: 12px;
            height: 2px;
            background-color: #777777;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -6px;
          }
          &::before {
            transform: rotate(45deg);
            margin-top: -4px;
          }
          &::after {
            transform: rotate(-45deg);
            margin-top: 4px;
          }
        }

        &.is-open {
          > span {
            font-weight: bold;
          }
        }

        ul {
          display: none;
        }

        &[data-tree-item-type="back"] {
          display: none;

          span {
            padding-left: 0;
          }
          
          i {
            transform: rotate(180deg);
            margin-left: 10px;
          }
        }

        &[data-tree-item-catid="Sale"] {
          > span {
            color: #FF0000;
          }
        }
      }
    }

    &.open-level-1 {
      left: -156px;

      > ul {
        width: 156px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: #ffffff;

        li[data-tree-item-type="back"] {
          display: flex;
        }

        li[data-tree-item-type="all"] {
          display: none;
        }

        > li.is-open {
          background-color: #F7F7F7;

          > ul {
            background-color: #F7F7F7;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            width: calc(100vw - 156px);

            > li[data-tree-item-type="back"] {
              display: none;
            }
    
            > li[data-tree-item-type="all"] {
              display: flex;
            }
          }
        }
      }
    }

    &.open-level-2 {
      left: -156px;

      > ul {
        width: 156px;
        position: absolute;
        top: 0;
        left: -156px;
        bottom: 0;
        background-color: #ffffff;

        li[data-tree-item-type="back"] {
          display: flex;
        }

        li[data-tree-item-type="all"] {
          display: none;
        }

        > li.is-open {
          background-color: #F7F7F7;

          > ul {
            background-color: #ffffff;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            width: 156px;

            > li.is-open {
              background-color: #F7F7F7;

              > ul {
                background-color: #F7F7F7;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                width: calc(100vw - 156px);

                > li[data-tree-item-type="back"] {
                  display: none;
                }
        
                > li[data-tree-item-type="all"] {
                  display: flex;
                }
              }
            }
          }
        }
      }
    }

    &.open-level-3 {
      left: -312px;

      > ul {
        width: 156px;
        position: absolute;
        top: 0;
        left: -156px;
        bottom: 0;
        background-color: #ffffff;

        li[data-tree-item-type="back"] {
          display: flex;
        }

        li[data-tree-item-type="all"] {
          display: none;
        }

        > li.is-open {
          background-color: #F7F7F7;

          > ul {
            background-color: #ffffff;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            width: 156px;

            > li.is-open {
              background-color: #F7F7F7;
    
              > ul {
                background-color: #ffffff;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                width: 156px;
    
                > li.is-open {
                  background-color: #F7F7F7;
    
                  > ul {
                    background-color: #F7F7F7;
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 100%;
                    width: calc(100vw - 156px);

                    > li[data-tree-item-type="back"] {
                      display: none;
                    }
            
                    > li[data-tree-item-type="all"] {
                      display: flex;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.open-level-4 {
      left: -468px;

      > ul {
        width: 156px;
        position: absolute;
        top: 0;
        left: -156px;
        bottom: 0;
        background-color: #ffffff;

        li[data-tree-item-type="back"] {
          display: flex;
        }

        li[data-tree-item-type="all"] {
          display: none;
        }

        > li.is-open {
          background-color: #F7F7F7;

          > ul {
            background-color: #ffffff;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            width: 156px;

            > li.is-open {
              background-color: #F7F7F7;
    
              > ul {
                background-color: #ffffff;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                width: 156px;
    
                > li.is-open {
                  background-color: #F7F7F7;
        
                  > ul {
                    background-color: #ffffff;
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 100%;
                    width: 156px;
        
                    > li.is-open {
                      background-color: #F7F7F7;
        
                      > ul {
                        background-color: #F7F7F7;
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 100%;
                        width: calc(100vw - 156px);
                        
                        > li[data-tree-item-type="back"] {
                          display: none;
                        }
                
                        > li[data-tree-item-type="all"] {
                          display: flex;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
