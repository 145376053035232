.yesplz-brand-filter {
  form {
    input {
      width: 100%;
      height: 48px;
      font-size: 13px;
      line-height: 18px;
      padding: 9px 15px 9px 42px;
      border-radius: 8px;
      border: solid 1px #dddfe2;
      margin-bottom: 16px;

      background: url('~@yesplz/core-web/assets/images/search-icon.svg') 12px center no-repeat;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .brands-selected {
    margin-left: -5px;
    margin-right: -5px;
    white-space: nowrap;
    overflow-x: scroll;
    padding: 0 15px;
    margin-bottom: 3px;

    span {
      margin: 8px 5px;
      font-size: 14px;
      display: inline-block;
      padding: 4px 20px 4px 4px;
      position: relative;
      cursor: pointer;

      &:hover {
        background-color: rgba($color: #e3e3e3, $alpha: .3);
      }

      &:before, &:after {
        content: '';
        display: block;
        width: 1px;
        height: 10px;
        background-color: rgba($color: #000000, $alpha: .4);
        position: absolute;
        top: 50%;
        right: 10px;
      }

      &:before {
        transform: translateY(-50%) rotate(45deg);
      }
      &:after {
        transform: translateY(-50%) rotate(-45deg);
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      outline: none;
      opacity: 0.5;
      border-radius: 2px;
      background-color: rgba(22, 22, 22, 0.05);
    }
  }

  ul {
    &.is-hidden {
      display: none;
    }

    li {
      span {
      }
    }
  }
}
