.ProductsPage {
  height: 100vh;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .ProductsList {
    flex-basis: 100%;
    flex-shrink: 1;
    overflow-y: scroll;
    padding: 40px 20px 0px;

    @include media-breakpoint-up(xl) {
      padding: 40px 60px 0px;
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
  
    &::-webkit-scrollbar-track {
      // background-color: #F7F7F7;
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      outline: none;
      opacity: 0.5;
      border-radius: 2px;
      background-color: rgba(22, 22, 22, 0.2);
    }
  }
}

.ProductList-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  min-height: 200px;

  h2 {
    display: block;
    position: relative;
    width: 100%;
    padding: 0 5px;
  }

  .ProductGrid {
    color: #000000;
    text-decoration: none;
    display: block;
    position: relative;
    overflow: hidden;

    width: calc(50% - 10px);

    @include media-breakpoint-up(lg) {
      width: calc(25% - 10px);
    }

    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;

    .ProductGrid-thumbnail {
      height: 300px;
      text-align: center;

      img {
        width: auto;
        height: 100%;
        object-position: top center;
        object-fit: cover !important;
      }

      @media screen and (max-width: 989px) {
        height: 180px;
      }

      @media screen and (max-width: 1119px) {
        height: 214px;
      }
    }

    .ProductGrid-detail {
      width: 100%;
      padding: 20px 0px 12px 0px;

      h5 {
        font-size: 1em;
        font-weight: 900;
        color: #212121;
        text-transform: uppercase;
        margin-bottom: 6px;
        width: calc(100% - 1em);

        @include three-line-text-elipsis;
      }

      .ProductGrid-originalPrice, .ProductGrid-price {
        font-size: 0.875em;
        font-weight: 600;
        line-height: 14px;
        display: inline;
        vertical-align: top;
      }

      .ProductGrid-originalPrice {
        margin-right: 16px;
        text-decoration: line-through;
      }

      .ProductGrid-price {
        color: #2F30EB;
      }
    }
  }
}
