@mixin fullAbsolute ($value: 0) {
  position: absolute;
  right: $value;
  bottom: $value;
  left: $value;
  top: $value;
}

.ColorPallete {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 20px;

  &-clear {
    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
    width: 40px;
    height: 40px;
    margin: 6px 3px;

    border: 1.25px solid rgba($dark-grey, 0.2);
    border-radius: 100%;
    outline: none;
    cursor: pointer;
  }
}

.ColorButton {
  background-color: transparent;
  margin: 6px 0;
  padding: 0;
  position: relative;
  outline: none;
  vertical-align: top;
  box-shadow: none;
  word-break: keep-all;
  white-space: nowrap;

  &:hover, &:active, &:focus {
    background-color: transparent;
  }

  cursor: pointer;

  span {
    display: block;
    text-align: center;
    text-transform: capitalize;
    word-break: normal;
    font-weight: 700;
    font-size: 12px;
    padding-top: 5px;
  }

  &-color-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0;
    width: 40px;
    height: 40px;
    border: 1 solid rgba($dark-grey, 0.1);
    border-radius: 100%;
    box-sizing: border-box;
    transition: padding 0.1s ease-in;
    overflow: hidden;
  }

  &-color {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    box-sizing: border-box;
    background-position: center center;
    background-size: cover;
  }

  &.ColorButton-white > &-color, &.ColorButton-white > &-color-wrapper > &-color {
    border: 1px solid rgba($dark-grey, 0.1);
  }

  &.ColorButton-clear > &-color-wrapper {
    border: 1px solid rgba($dark-grey, 0.1);
    border-radius: 50%;
  }

  &-mask {
    border-radius: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    @include fullAbsolute;

    &::before {
      content: '';
      border-radius: 100%;
      @include fullAbsolute;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("~@yesplz/core-models/assets/svg/check.svg");
    }
  }

  &.ColorButton-metalic &-mask::before, &.ColorButton-white &-mask::before {
    background-image: url("~@yesplz/core-models/assets/svg/check-dark.svg");
  }

  &.disabled {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
  }

  &.active {
    .ColorButton-mask {
      display: block;
    }

    span {
      font-weight: bold;
    }
  }
}
