.CollapsibleFilters-container {
  z-index: 1000;
}

.CollapsibleFilters {
  border-radius: 0;
  display: flex;
  flex-direction: column;

  &-Foldable {
    height: calc(100vh);
    height: calc(var(--vh, 1vh) * 100);
  }

  &-header {
    border-color: #000000;
    margin: 0;
    padding-left: 60px;

    @media (min-width: 768px) {
      padding-left: 50px;
    }

    &-actions {
      justify-content: center;

      #collapsible-clear-button {
        display: none;
      }
    }

    &-close {
      display: flex;

      @media (min-width: 768px) {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }

  &-footer {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0;
    box-shadow: none;

    button {
      border: 1px solid #000000;
      background-color: #000000;
      cursor: pointer;

      &#collapsible-footer-clear-button {
        background-color: #ffffff;
        color: #000000;
      }
    }
  }
}

.Collapsible {
  &-header {
    &-title {
      h3 {
        font-size: 16px !important;
        color: #222;
        font-weight: bold !important;
        
      }
      .filter-value {
        flex: 1;
        color: #222 !important;
        opacity: 0.8;
      }
      .filter-spreader {
        display: none !important;
      }
    }
  }
  &::after {
    left: 0;
    right: 0;
    background-color: #f3f3f3;
  }

  &[data-filter="colors"] {
    .Collapsible-header {
      &-title {
        .filter-value {
          text-transform: uppercase;
        }
      }
    }
  }
}

.yesplz-brand-filter {
  .brands-selected {
    > span {
      border: 1px solid #f3f3f3;
      padding: 4px 22px 4px 6px;
    }
  }

  ul {
    li {
      display: flex !important;

      &::before {
        width: 18px !important;
        height: 18px !important;
        border: 0 !important;
        background: url("./assets/form.png") no-repeat 0 0;
        background-size: 100px;
      }

      &::after {
        display: none !important;
      }

      &.active, &.is-selected {
        &::before {
          background-position: 0 -50px;
        }
      }
    }
  }
}

.yesplz-tree-view {
  ul {
    padding-left: 0;

    li {
      span {
        display: block;
        margin-top: 10px;
      }

      &.active {
        > span {
          font-weight: bold;
        }
      }
    }
  }

  > ul {
    margin-bottom: 20px;

    > li {
      > span {
        font-size: 16px;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 13px;
      }

      > ul {
        padding-left: 35px;
        margin-bottom: 15px;

        > li {
          > span {
            margin-top: 15px;
          }

          &:first-child {
            > span {
              margin-top: 0;
            }
          }

          > ul {
            padding-left: 35px;
            margin-bottom: 15px;

            > li {
              position: relative;
              &.active {
                span::before {
                  content: '';
                  display: inline-block;
                  width: 20px;
                  height: 12px;
                  background: url("./assets/form.png") no-repeat;
                  background-position: -96px -67px;
                  background-size: 120px 120px;
                  margin-right: 10px;
                  position: absolute;
                  top: 2px;
                  left: -30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.yesplz-brand-filter {
  .brands-selected {
    margin-left: -20px;
    margin-right: -20px;

    span {
      display: inline-block;
      padding: 0 22px 0 6px;
      line-height: 30px;
    }
  }
}

.ColorButton span {
  font-size: 12px;
  font-weight: 800;

  @media (min-width: 768px) {
    font-size: 8px;
  }  
}
