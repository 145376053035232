$c: 'Collapsible';

.CollapsibleFilters-container {
  position: relative;
  z-index: 100;

  @include media-breakpoint-up(md) {
    z-index: auto;
  }

  * {
    box-sizing: border-box;
  }

  .CollapsibleFilters-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(0,0,0,0.2);

    @include media-breakpoint-up(md) {
      display: none;
    }

    &.is-hidden {
      display: none;
    }
  }
}

.CollapsibleFilters {
  background-color: #ffffff;

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  transition: transform 0.4s ease;

  &-Foldable {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    height: 570px;
    overflow-x: hidden;
    overflow-y: auto;

    @include media-breakpoint-up(md) {
      z-index: 0;
    }
  }

  @include media-breakpoint-up(md) {
    width: 348px;
    z-index: 0;
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    height: auto;
    overflow: visible;
    transform: translate3d(0, 0, 0) !important;
  }

  &-pointer {
    z-index: 5;
  }

  &-tooltip {
    background-color: #2F30EB;
    color: white;
    padding: 15px 15px;
    border-radius: 4px;
    font-size: 13px;

    position: absolute;
    top: 0;
    // left: 0 !important;
    width: calc(100% - 40px);
    z-index: 4;

    &.before-start {
      display: none;
    }

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background-color: #2F30EB;

      position: absolute;
      top: -10px;
      right: 80px;

      transform: rotate(45deg);
    }

    &[data-popper-placement^='top']:before {
      top: auto;
      bottom: -10px;
    }

    &[data-popper-placement*='start']:before {
      right: auto;
      left: 80px;

      margin-left: 0 !important;
      margin-right: 20px !important;
    }

    &[data-popper-placement*='end']:before {
      margin-left: 20px !important;
      margin-right: 0 !important;
    }

    &[data-popper-reference-hidden] {
      visibility: hidden;
      pointer-events: none;
    }

    h4 {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 16px;
    }

    p {
      font-size: 14px;
      color: rgba(255,255,255,0.8);
      margin-bottom: 0;
    }

    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
      border: 0 solid transparent;
      width: 16px;
      height: 16px;
      cursor: pointer;

      &:before, &:after {
        display: block;
        position: absolute;
        left: 4px;
      }
    }

    &-footer {
      text-align: right;
    }

    &-button {
      background-color: transparent;
      border-color: transparent;
      display: block;
      cursor: pointer;
    }
  }

  &-header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 14px 20px;
    height: 70px;
    position: relative;
    border-bottom: 1px solid #f0f1f3;
    background-color: #ffffff;

    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 6;

    @include media-breakpoint-up(md) {
      padding-left: 0;
      padding-right: 0;
      margin-left: 20px;
      margin-right: 20px;
    }

    &-actions {
      flex: 1;
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 41px;
      }

      button {
        font-size: 13px;
        color: #131314;
        border: 0;
        background-color: transparent;
        text-decoration: underline;
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        &:active {
          opacity: 0.9;
        }
      }

      span {
        color: #8b8c8f;
        font-size: 14px;
        display: flex;
        align-items: center;
      }
    }

    &-close {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: -10px;

      @include media-breakpoint-up(md) {
        display: none;
      }

      > img {
        vertical-align: top;
        width: 16px;
        height: 16px;
        opacity: 0.6;
      }
    }
  }

  .simple-presets-occasions-tags {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0;
    margin-right: 0;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &-footer {
    margin-top: 30px;
    padding: 0 20px 20px;
    position: sticky;
    bottom: 0;
    z-index: 6;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px -15px 30px 10px #ffffff;

    @include media-breakpoint-up(md) {
      display: none;
    }

    button {
      border: 0;
      background-color: #2F30EB;
      padding: 10px;
      color: #ffffff;
      font-size: 14px;
      width: 100%;
      // width: 51%;
      height: 48px;

      svg {
        margin-left: 10px;
      }
    }
  }
}

.#{$c} {
  padding: 0 20px;
  position: relative;
  z-index: 0;

  &.is-hidden {
    display: none;
  }

  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: #f0f1f3;

    position: absolute;
    bottom: 0;
    left: 24px;
    right: 24px;
    z-index: 4;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  &.is-sticky {}

  &-header {
    position: relative;
    cursor: pointer;
    height: 56px;
    background-color: #ffffff;
    z-index: 3;
    padding-right: 37px;

    .is-sticky & {
      position: sticky;
      top: 66px;
      border-bottom: 1px solid #f0f1f3;
    }

    &-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 56px;

      h3 {
        font-size: 15px !important;
        margin: 0 12px 0 0 !important;
        font-weight: normal !important;
        text-transform: none !important;
        line-height: 14px;
        display: block;
        white-space: nowrap;
      }

      span.filter-spreader {
        display: block;
        flex: 1;
      }

      span.filter-value, span.filter-action {
        font-size: 12px;
        color: #8b8c8f;
        line-height: 14px;

        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
      span.filter-action {
        font-size: 14px;
        cursor: pointer;
        color: #131314;
        padding: 6px 12px;
        text-decoration: underline;

        &:hover {
          border-bottom-width: 0;
        }
      }
    }

    span.plus {
      display: block;
      width: 25px;
      height: 25px;

      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      &::before {
        display: block;
        content: '';
        position: absolute;
        background-color: #131314;
        width: 13px;
        height: 1px;
        left: 7px;
        top: 13px;

        transition: all 0.5s ease-in-out;
      }

      &::after {
        display: block;
        content: '';
        position: absolute;
        background-color: #131314;
        width: 1px;
        height: 13px;
        left: 13px;
        top: 7px;

        transition: all 0.5s ease-in-out;
        opacity: 1;
      }
    }
  }

  &-content {
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    max-height: 0;

    h3 {
      display: none;
    }
  }

  &.open {
    .#{$c}-header {
      span.plus {
        &::before {
          transform: rotate(90deg);
          opacity: 0;
        }

        &::after {
          transform: rotate(90deg);
        }
      }
    }

    .#{$c}-content {
      overflow: initial;
    }
  }

  &.is-animating {
    .#{$c}-content {
      overflow: hidden;
    }
  }

  .ThumbnailPicker {
    padding: 0;
    padding-bottom: 15px;

    margin-left: 0;
    margin-right: 0;

    width: auto;
    align-items: stretch;
    position: relative;

    @include media-breakpoint-up(md) {
      margin-left: 0;
      margin-right: 0;
    }

    .ThumbnailPickerOption {      
      margin-right: 0;

      &:nth-child(3n+1), &:nth-child(3n+3) {
        width: 80px;

        @include media-breakpoint-up(md) {
          width: 64px;
        }
      }

      &:nth-child(3n+2) {
        width: calc(100% - (80px*2));
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up(md) {
          width: calc(100% - (64px*2));
        }
      }

      .ThumbnailPickerOption-thumbnail {
        &::before {
          background-color: transparent;
          max-height: 100%;
          max-width: 100%;
          border: solid 1px #dddfe2;
          opacity: 1;
        }

        &--borderless {
          &::before {
            border-color: transparent;
          }
        }

        .ThumbnailPickerOption-imageWrapper {
          img {
            display: block;
            width: 37px;
            height: 37px;

            @include media-breakpoint-up(md) {
              width: 27px;
              height: 27px;
            }
          }
        }
      }

      &.is-active {
        .ThumbnailPickerOption-thumbnail {
          &::before {
            border: solid 2px #2F30EB;
          }
        }
      }

      &.reset {
        .ThumbnailPickerOption-thumbnail {
          .ThumbnailPickerOption-imageWrapper {
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      &.patterns-pattern {
        .ThumbnailPickerOption-thumbnail {
          .ThumbnailPickerOption-imageWrapper {
            img {
              width: 29px;
              height: 29px;

              @include media-breakpoint-up(md) {
                width: 23px;
                height: 23px;
              }
            }
          }
        }
      }

      &.patterns-detail, &.pattern-detail {
        .ThumbnailPickerOption-thumbnail {
          .ThumbnailPickerOption-imageWrapper {
            img {
              width: 45px;
              height: 45px;

              @include media-breakpoint-up(md) {
                width: 38px;
                height: 38px;
              }
            }
          }
        }
      }

      &.patterns-floral, &.pattern-floral {
        .ThumbnailPickerOption-thumbnail {
          .ThumbnailPickerOption-imageWrapper {
            img {
              width: 78px;
              height: 80px;

              @include media-breakpoint-up(md) {
                width: 64px;
                height: 64px;
              }
            }
          }
        }
      }

      &.pattern-animal, &.pattern-washed_dark, &.pattern-washed_medium,
      &.pattern-washed_light, &.pattern-stripe, &.pattern-checker,
      &.pattern-graphic, &.pattern-colorblock, &.pattern-dot,
      &.pattern-fruit, &.pattern-repeat, &.pattern-sparkle,
      &.wide-image {
        .ThumbnailPickerOption-thumbnail {
          .ThumbnailPickerOption-imageWrapper {
            img {
              width: 80px;
              height: 80px;

              @include media-breakpoint-up(md) {
                width: 64px;
                height: 64px;
              }
            }
          }
        }
      }
    }
  }

  .ColorPallete {
    padding: 0;
    justify-content: flex-start;
    padding-bottom: 25px;

    .ColorButton-wrapper {
      width: auto;

      &:nth-child(4n+1) {
        padding-left: 0;
      }

      &:not(:nth-child(4n+1)) {
        padding-left: calc((100% - (64px * 4)) / 3);

        @include media-breakpoint-up(md) {
          padding-left: calc((100% - (48px * 4)) / 3);
        }
      }
    }

    .ColorButton {
      border: 0;
      box-shadow: none;
      width: 64px;

      @include media-breakpoint-up(md) {
        width: 48px;
      }

      &-color-wrapper {
        width: 64px;
        height: 64px;

        @include media-breakpoint-up(md) {
          width: 48px;
          height: 48px;
        }
      }

      &-color {
        width: 100%;
        height: 100%;
      }

      .ColorButton-mask::before {
        background-size: 14px 12px;
      }

      &.clear {
        .ColorButton-color-wrapper {
          border: solid 1px #dddfe2;

          img {
            width: 21px;
            height: 21px;
          }
        }
      }

      &.active {
        background: transparent !important;

        .ColorButton-color-wrapper {
          padding: 4px;
          border: solid 1px #dddfe2;
        }
      }
    }
  }

  .yesplz-price-range-container {
    padding: 20px 12px;
  }

  .yesplz-price-range-slider {
    &.noUi-horizontal {
      height: 4px;
    }

    &.noUi-target {
      border: 0;
      border-radius: 2px;
      background-color: #dddfe2;
    }

    .noUi-base {
      .noUi-connects {
        .noUi-connect {
          background-color: #131314;
        }
      }

      .noUi-origin {
        .noUi-handle {
          border: solid 2px #ffffff;
          background-color: #131314;
          width: 16px;
          height: 16px;
          top: -6px;
          right: -7px;

          .noUi-touch-area {
            width: 400%;
            height: 400%;
            transform: translate3d(-18px, -18px, 0);
          }
        }
      }
    }
  }

  .yesplz-price-from-to-filter {
    display: flex;
    justify-content: space-between;

    * {
      box-sizing: border-box;
    }

    span {
      display: block;
      width: 48%;

      input {
        width: 100%;
        border-radius: 8px;
        border: solid 1px #dddfe2;
        line-height: 16px;
        padding: 12px 16px;
        color: #8b8c8f;
        box-shadow: none;
        -webkit-appearance: none;

        &:focus {
          outline: none
        }
      }
    }
  }

  #collapsible-style-filter {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    h3 {
      display: block;
      width: 100%;
      font-size: 15px;
      font-weight: normal;
      line-height: 1.6;
      color: #333333;
    }
  }

  #collapsible-patterns-filter {
    > div {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }

  .simple-categories-list {
    padding-bottom: 25px;
  }

  .EditorsPicks {
    &-container {
      padding: 0;
      margin: 0 -5px;

      .EditorsPicks-Item {
        border-radius: 8px;
        box-shadow: 0px 0px 0px 1px #dddfe2;
        margin-left: 6px;
        margin-right: 6px;
        width: auto;
        padding: 8.5px 17px;

        h5 {
          font-size: 14px;
          text-transform: none;
        }

        &.is-active {
          box-shadow: 0px 0px 0px 1px #131314;
          background-color: #131314;

          h5 {
            color: #ffffff;
          }
        }
      }
    }
  }

  .VisualFilter {
    position: relative;

    &:before {
      content: "";
      width: 68.50828729281768%;
      height: 97.637795275590551%;
      background: #01070d;
      opacity: 0.02;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      margin: auto;
      z-index: 0;
    }

    svg {
      position: relative;
      z-index: 1;

      .vf-background {
        fill: #fafafa;
      }
    }
  }

  &.is-sticky {
    .yesplz-brand-filter form {
      position: sticky;
      top: 122px;
      z-index: 1;
      border-bottom: 1px solid #f0f1f3;
      background-color: #ffffff;
    }

    &[data-filter="brand"] {
      .Collapsible-header {
        border-bottom: 0;
      }
    }
  }

  .yesplz-brand-filter {
    ul {
      padding-bottom: 20px;
      list-style-type: none !important;

      li {
        cursor: pointer;
        position: relative;
        padding-left: 28px;
        margin-bottom: 10px;
        line-height: 30px;
        height: 30px;
        display: flex;
        align-items: center;

        span {
          font-size: 13px;
          text-transform: none;
        }

        &::before, &::after {
          content: '';
          display: block;
          border: solid 1px #d4d4d5;
          width: 16px;
          height: 16px;
          border-radius: 3px;
          margin-right: 12px;

          position: absolute;
          left: 0;
          top: 6px;
        }

        &::after {
          display: none;
          width: 10px;
          height: 10px;
          border-radius: 2.1px;
          background-color: #2F30EB;
          border: 0;
        }

        &.active, &.is-selected {
          &::before {
            border-color: #2F30EB;
          }

          &::after {
            display: block;
            left: 4px;
            top: 10px;
          }
        }

        &.is-disabled {
          opacity: 0.3;
          cursor: default;
        }

        &.header {
          padding-top: 12px;
          padding-left: 4px;
          cursor: default;

          &:first-child {
            padding-top: 0;
          }

          span {
            font-weight: bold;
            color: #000000;
          }

          &::before, &::after {
            display: none;
          }
        }
      }
    }
  }

  // .simple-categories-list, .yesplz-brand-filter {
  //   max-height: 300px;
  //   overflow-x: hidden;
  //   overflow-y: auto;
  //   margin-bottom: 20px;
  //   padding-bottom: 0;

  //   &::-webkit-scrollbar {
  //     width: 2px;
  //   }

  //   &::-webkit-scrollbar-track {
  //     background-color: #f0f1f3;
  //   }

  //   &::-webkit-scrollbar-thumb {
  //     background-color: rgba(57, 57, 57, 0.3);
  //     outline: none;
  //     opacity: 0.5;
  //     border-radius: 0;
  //     background-color: #000;
  //   }
  // }
}
