$prefix: 'VisualFilter';

.#{$prefix} {
  position: relative;

  .onboarding-pointer {
    position: absolute;
    background: url('~@yesplz/core-models/assets/svg/vf-onboarding-cursor.png') no-repeat;
    width: 66px;
    height: 77px;
    z-index: 5;
    margin-left: 20px;
    margin-top: 3px;
    transform: scale(0.8);
  }

  #visualfilter-svg {
    user-select: none;

    *[fill="#582ADF"] {
      fill: #2F30EB;
    }
  
    *[stroke="#582ADF"] {
      stroke: #2F30EB;
    }

    #tags {
      > text {
        text-transform: uppercase;
      }

      path, text {
        cursor: default;
      }

      path {
        stroke: #A4AFC1;
      }
    }

    .action-tag {
      cursor: default;

      rect, text, path {
        visibility: visible;
      }
    }
    
    .option-all, .disabled {
      *[fill="#582ADF"], &[fill="#582ADF"] {
        fill: #707172;
      }
    
      *[stroke="#582ADF"], &[stroke="#582ADF"] {
        stroke: #707172;
      }
    }

    &.not-interacted {
      .touch-point-selected {
        g[data-type="selected"], g[data-type="hover"] {
          .svg-pulsar {
            fill: #2F30EB;
            opacity: 0.2;
          }
        }
      }
    }
  }

  &.empty {
    display: none;
  }

  .#{$prefix}-navigation {
    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
      border: none;
      border-radius: 100%;
      bottom: auto;
      cursor: pointer;
      user-select: none;
      visibility: visible;
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-position: center center;

      position: absolute;
      z-index : 10;

      top: 100px;

      @include media-breakpoint-up(md) {
        top: 50%;
        transform: translate(0, -50%);
      }

      &-left {
        left: 20px;
        background-image: url('~@yesplz/core-models/assets/svg/angle-left.svg');
      }

      &-right {
        right: 20px;
        background-image: url('~@yesplz/core-models/assets/svg/angle-right.svg');
      }

      img {
        width: 8px;
      }
    }

    &.active {
      .#{$prefix}-navigation-button {
        &.#{$prefix}-navigation-button-left {
          background-image: url('~@yesplz/core-models/assets/svg/angle-left-blue.svg');
        }
        &.#{$prefix}-navigation-button-right {
          background-image: url('~@yesplz/core-models/assets/svg/angle-right-blue.svg');
        }
      }
    }
  }

  &-buttons {
    position: absolute;
    z-index: 2;
    // right: 16px;
    top: 10px;
    left: 0;
    width: 100%;
  }

  &-button {
    border: 0;
    background-color: #ffffff;
    outline: none;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    color: #787878;
    text-decoration: underline;
    font-size: 16px;
    
    &:hover {
      text-decoration: none;
    }

    &.VisualFilter-favorite-button {
      right: 0;
    }

    &.VisualFilter-clear-button {
      left: 12px;
      background-color: transparent;
    }
  }
}