
.yesplz-tree-view {
  ul {
    padding-left: 10px;

    li {
      cursor: pointer;

      ul {
        display: none;
      }

      &.active {
        > ul {
          display: block;
        }
      }

      .toggler {
        float: right;
        display: block;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }

      > div.subcategories {
        ul {
          display: block;
          max-height: fit-content !important;
          margin-bottom: 0;
          padding: 15px 0 10px 20px;
        }
      }

      &.opened {
        .toggler {}
      }
    }
  }

  > ul {
    padding-left: 0;
  }
}